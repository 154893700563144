import React from "react"
import BaseLayout from "../../../components/Layout"
import SelectTypeReport from "../../../components/Relatorios/SelectTypeReport"

const PageRelatoriosBySetor = () => {
  return (
    <BaseLayout>
      <div className="container">
        <SelectTypeReport />
      </div>
    </BaseLayout>
  );
}

export default PageRelatoriosBySetor
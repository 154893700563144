/* eslint-disable react/style-prop-object */
import React from "react";
import "./_styles.scss";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import RaioxImg from "../../assets/raiox.png";
import PorticoImg from "../../assets/portico.png";
import BaseLayout from "../../components/Layout";
import FilterSetorMonitora from "../../components/FilterSetoresMonitora";
import { actionGetPontosMonitor } from "../../features/PontosMonitor/slices";
import Select from "../../components/Select";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getUser } from "../../helpers/user";
import Progress from "../../components/Progress";

const initialState = {
    filter: null,
    listItensSetor: [],
} as any;

let responseSocket;

const contador = {
    num: 0,
}

const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SOCKET}/hubPtosMonitor`, {
        accessTokenFactory: () => getUser().token,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

const PageTeste = () => {    

    const dispatch = useAppDispatch();    

    const { getPontosMonitor } = useAppSelector((state) => state)
            
    const [state, setState] = React.useState(initialState)
    
    /*const getSetorSelected = (filter) => setState({ ...state, filter })*/
    const getSetorSelected = (filter) => {
        console.log(filter)
        if (filter === null || filter === 0) {
            setState({ ...state, filter: null, listItensSetor: [] });
        } else {
            setState({ ...state, filter });
        }
    };
    
    const createList = (data) => {
        if (!data || !state.filter) return;

        const filtered = data.filter(({ id }) => id === state.filter);
        const listItensSetor = filtered;

        setState({
            ...state,
            listItensSetor,
        });
    };

    React.useEffect(() => {
        //testar dispatch
        dispatch(actionGetPontosMonitor())
        connection.start().then(() => console.log('connection started aqui'));
    }, []);

    React.useEffect(() => {

        connection.on('SocketPtosMonitor', function (data) {
            responseSocket = data
            contador.num++
            if (contador.num > 1000) {
                contador.num = 1
            }
            //console.log(contador)
            createList(responseSocket.setores)            
        });

        if (responseSocket) {
            console.log(responseSocket.setores)
            createList(responseSocket.setores)
            console.log("opcao1")
        } else {
            console.log(getPontosMonitor.data)
            createList(getPontosMonitor.data.setores)
            console.log("opcao2")
        }
        
    }, [getPontosMonitor.data, state.filter, contador.num]);

    let fluxoPorticoTeste = 20;
    //col-lg-5 col-md-12 col-sm-12
    return (
        <BaseLayout>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 co-sm-12 mb-20">
                        <FilterSetorMonitora cb={getSetorSelected} />
                    </div>
                </div>
            </div>
            <div className="list-detahamento container col-12">
                {state.listItensSetor ? (
                    state.listItensSetor.length > 0 ? (
                        (() => {
                            const allModules = state.listItensSetor.flatMap(setor => setor.listModuloRxPrt);
                            const metade = Math.ceil(allModules.length / 2);
                            const primeiraMetade = allModules.slice(0, metade);
                            const segundaMetade = allModules.slice(metade);

                            const renderModules = (modules) => (
                                modules.map((modulo) => {
                                    const { idModulo, monRaiox, monPortico } = modulo;

                                    const temRaiox = monRaiox !== null;
                                    const temPortico = monPortico !== null;
                                    const RaioxLadoD = temRaiox && monRaiox.lado === "D";
                                    const PorticoLadoD = temPortico && monPortico.lado === "D";

                                    return (
                                        <React.Fragment key={idModulo}>
                                            {!RaioxLadoD && temRaiox && (
                                                <div className={`card-equipamentos__content ${monRaiox.rxEnergizado ? "normal" : "accent"} col-12`}>
                                                    <div className="contentItensRaiox">
                                                        <div className="nameEquip col-4">
                                                            <strong>{monRaiox.equipamento.nome + " " + monRaiox.descricao}</strong>
                                                            
                                                        </div>
                                                        <div className="figureEquip col-4">
                                                            <div className="figure__icon">
                                                                <img src={RaioxImg} alt="Raio-X" />
                                                            </div>
                                                        </div>
                                                        <div className="estadoRaiox col-4">
                                                            <div className={`cardEstadoRaiox ${monRaiox.rxLogado ? "positive" : "negative"}`}>
                                                                <strong>{monRaiox.rxLogado ? "EM OPERACAO" : (!monRaiox.rxEnergizado ? "DESLIGADO" : "SEM OPERADOR")}</strong>
                                                            </div>
                                                            <p>Modulo: {modulo.idModulo}</p>
                                                        </div>
                                                    </div>
                                                    <div className="infoPost">{monRaiox.lastPostString}</div>
                                                </div>
                                            )}

                                            {!PorticoLadoD && temPortico && (
                                                <div className={`card-equipamentos__content ${monPortico.prtLigado ? "normal" : "accent"} col-12`}>
                                                    <div className="contentItensPortico">
                                                        <div className={`contagemFluxo ${monPortico.prtLigado ? "" : "offline"} col-4`}>
                                                            <div className={`grau10 ${fluxoPorticoTeste > 50 && fluxoPorticoTeste >= 55 ? "active" : ""}`}></div>
                                                            <div className={`grau9 ${fluxoPorticoTeste > 45 && fluxoPorticoTeste >= 50 ? "active" : ""}`}></div>
                                                            <div className={`grau8 ${fluxoPorticoTeste > 40 && fluxoPorticoTeste >= 45 ? "active" : ""}`}></div>
                                                            <div className={`grau7 ${fluxoPorticoTeste > 35 && fluxoPorticoTeste >= 40 ? "active" : ""}`}></div>
                                                            <div className={`grau6 ${fluxoPorticoTeste > 30 && fluxoPorticoTeste >= 35 ? "active" : ""}`}></div>
                                                            <div className={`grau5 ${fluxoPorticoTeste > 25 && fluxoPorticoTeste >= 30 ? "active" : ""}`}></div>
                                                            <div className={`grau4 ${fluxoPorticoTeste > 20 && fluxoPorticoTeste >= 25 ? "active" : ""}`}></div>
                                                            <div className={`grau3 ${fluxoPorticoTeste > 15 && fluxoPorticoTeste >= 20 ? "active" : ""}`}></div>
                                                            <div className={`grau2 ${fluxoPorticoTeste > 10 && fluxoPorticoTeste >= 15 ? "active" : ""}`}></div>
                                                            <div className={`grau1 ${fluxoPorticoTeste >= 10 ? "active" : ""}`}></div>
                                                        </div>
                                                        <div className="figureEquip col-4">
                                                            <div className="figure__icon">
                                                                <img src={PorticoImg} alt="Pórtico" />
                                                            </div>
                                                        </div>
                                                        <div className="estadoPortico col-4">
                                                            <div className="cardEstadoPortico">
                                                                <strong>{monPortico.prtLigado ? `${fluxoPorticoTeste} PESSOAS / 5 min` : "DESLIGADO"}</strong>
                                                            </div>
                                                            <p>Modulo: {modulo.idModulo}</p>
                                                        </div>
                                                    </div>
                                                    <div className="infoPost">{monPortico.lastPostString}</div>
                                                </div>
                                            )}

                                            {RaioxLadoD && (
                                                <div className={`card-equipamentos__content ${monRaiox.rxEnergizado ? "normal" : "accent"} col-12`}>
                                                    <div className="contentItensRaiox">
                                                        <div className="nameEquip col-4">
                                                            <strong>{monRaiox.equipamento.nome + " " + monRaiox.descricao}</strong>
                                                        </div>
                                                        <div className="figureEquip col-4">
                                                            <div className="figure__icon">
                                                                <img src={RaioxImg} alt="Raio-X" />
                                                            </div>
                                                        </div>
                                                        <div className="estadoRaiox col-4">
                                                            <div className={`cardEstadoRaiox ${monRaiox.rxLogado ? "positive" : "negative"}`}>
                                                                <strong>{monRaiox.rxLogado ? "EM OPERACAO" : (!monRaiox.rxEnergizado ? "DESLIGADO" : "SEM OPERADOR")}</strong>
                                                            </div>
                                                            <p>Modulo: {modulo.idModulo}</p>
                                                        </div>
                                                    </div>
                                                    <div className="infoPost">{monRaiox.lastPostString}</div>
                                                </div>
                                            )}

                                            {PorticoLadoD && (
                                                <div className={`card-equipamentos__content ${monPortico.prtLigado ? "normal" : "accent"} col-12`}>
                                                    <div className="contentItensPortico">
                                                        <div className={`contagemFluxo ${monPortico.prtLigado ? "" : "offline"} col-4`}>
                                                            <div className={`grau10 ${fluxoPorticoTeste > 50 && fluxoPorticoTeste >= 55 ? "active" : ""}`}></div>
                                                            <div className={`grau9 ${fluxoPorticoTeste > 45 && fluxoPorticoTeste >= 50 ? "active" : ""}`}></div>
                                                            <div className={`grau8 ${fluxoPorticoTeste > 40 && fluxoPorticoTeste >= 45 ? "active" : ""}`}></div>
                                                            <div className={`grau7 ${fluxoPorticoTeste > 35 && fluxoPorticoTeste >= 40 ? "active" : ""}`}></div>
                                                            <div className={`grau6 ${fluxoPorticoTeste > 30 && fluxoPorticoTeste >= 35 ? "active" : ""}`}></div>
                                                            <div className={`grau5 ${fluxoPorticoTeste > 25 && fluxoPorticoTeste >= 30 ? "active" : ""}`}></div>
                                                            <div className={`grau4 ${fluxoPorticoTeste > 20 && fluxoPorticoTeste >= 25 ? "active" : ""}`}></div>
                                                            <div className={`grau3 ${fluxoPorticoTeste > 15 && fluxoPorticoTeste >= 20 ? "active" : ""}`}></div>
                                                            <div className={`grau2 ${fluxoPorticoTeste > 10 && fluxoPorticoTeste >= 15 ? "active" : ""}`}></div>
                                                            <div className={`grau1 ${fluxoPorticoTeste >= 10 ? "active" : ""}`}></div>
                                                        </div>
                                                        <div className="figureEquip col-4">
                                                            <div className="figure__icon">
                                                                <img src={PorticoImg} alt="Pórtico" />
                                                            </div>
                                                        </div>
                                                        <div className="estadoPortico col-4">
                                                            <div className="cardEstadoPortico">
                                                                <strong>{monPortico.prtLigado ? `${fluxoPorticoTeste} PESSOAS / 5 min` : "DESLIGADO"}</strong>
                                                            </div>
                                                            <p>Modulo: {modulo.idModulo}</p>
                                                        </div>
                                                    </div>
                                                    <div className="infoPost">{monPortico.lastPostString}</div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            );

                            return (
                                <>
                                    <div className="row col-lg-6 col-md-12 col-sm-12" style={{ height: `100%` }}>
                                        {renderModules(primeiraMetade)}
                                    </div>
                                    <div className="row col-lg-6 col-md-12 col-sm-12" style={{height: `100%`}}>
                                        {renderModules(segundaMetade)}
                                    </div>
                                </>
                            );
                        })()
                    ) : (
                        <strong>Selecione um Canal de Inspecao para visualizar os equipamentos</strong>
                    )
                ) : (
                    <p>Nenhum equipamento encontrado</p>
                )}

                {/*teste demanda tadeu*/}
                {/*<div className="table-responsive">*/}
                {/*    <table className="table col-12">*/}
                {/*        <thead className="table-dark">*/}
                {/*            <tr>*/}
                {/*                <th scope="col"></th>*/}
                {/*                <th scope="col">STATUS</th>*/}
                {/*                <th scope="col">TEMPO ATIVO</th>*/}
                {/*                <th scope="col">FLUXO</th>*/}
                {/*                <th scope="col">ALERTAS</th>*/}
                {/*                <th scope="col">ALEATÓRIO</th>*/}
                {/*            </tr>*/}
                {/*        </thead>*/}
                {/*        <tbody>*/}
                {/*            {state.listItensSetor && state.listItensSetor.length > 0 ? (*/}
                {/*                state.listItensSetor.flatMap((setor) =>*/}
                {/*                    setor.listModuloRxPrt.map((modulo) => (*/}
                {/*                        <tr key={modulo.idModulo}>*/}
                {/*                            <th scope="row">*/}
                {/*                                {modulo.monRaiox ? (*/}
                {/*                                    <div className="circle"></div> // Substitua por seu código de círculo*/}
                {/*                                ) : modulo.monPortico ? (*/}
                {/*                                    <div className="rectangle"></div> // Substitua por seu código de retângulo*/}
                {/*                                ) : null}*/}
                {/*                            </th>*/}
                {/*                            <td>{modulo.monRaiox ? 'Raio-x Status' : 'Pórtico Status'}</td>*/}
                {/*                            <td>*/}{/* Tempo ativo */}{/*</td>*/}
                {/*                            <td>*/}{/* Fluxo */}{/*</td>*/}
                {/*                            <td>*/}{/* Alertas */}{/*</td>*/}
                {/*                            <td>*/}{/* Aleatório */}{/*</td>*/}
                {/*                        </tr>*/}
                {/*                    ))*/}
                {/*                )*/}
                {/*            ) : (*/}
                {/*                    <p>Nenhum equipamento encontrado</p>*/}
                {/*            )}*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*</div>*/}
            </div>
        </BaseLayout>
    );
};
export default PageTeste;

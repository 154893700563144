/* eslint-disable react/style-prop-object */
import React from "react";
//import "./_styles.css";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import BaseLayout from "../../components/Layout";
import { actionGetGradeBySetor } from "../../features/get-grade/slices"
import { actionUpdateGradeBySetor } from "../../features/update-grade/slices"
import FormGradeSetor from "./FormGradeSetor";
import FilterSetorMonitora from "../../components/FilterSetoresMonitora";   
import Input from "../../components/Inputs";
import Button from "../../components/Button";
import Alert from "../../components/Alert";
import { updateGradeBySetor } from "../../features/update-grade/services";

const initialState = {
    filter: '',//valor padr�o
    listGradeSetor: []
} as any;

const contador = {
    num: 0,
}

const FeedbackConfiguracoes = ({ visible }) => {
    const { updateGradeBySetor } = useAppSelector((state) => state);

    if (!visible) return null;

    if (updateGradeBySetor.status === "success") {
        return (
            <Alert type="success"><p>Configuracoes <strong>realizadas</strong> com sucesso!</p></Alert>
        );
    }

    if (updateGradeBySetor.status === "error") {
        return (
            <Alert type="error">
                {updateGradeBySetor.msgError
                    ? updateGradeBySetor.msgError
                    : "Configuracoes n�o realizadas, tente novamente!"}
            </Alert>
        );
    }
    return null
}

const Actions = ({ handleCancelClick, callbackConfirm, loading }) => {
    return (
        <div style={{display: 'flex'}}>
            <Button size="sm" variant="danger" onClick={handleCancelClick}>
                Cancelar
            </Button>
            <Button
                size="sm"
                variant="primary"
                onClick={callbackConfirm}
                loading={loading}
            >
                Confirmar
            </Button>
        </div>
    );
};

const PageConfigHorariosGrade = () => {
    const dispatch = useAppDispatch();
    const { updateGradeBySetor } = useAppSelector((state) => state);

    const loading = updateGradeBySetor.status === "loading"

    const { getGradeBySetor } = useAppSelector((state) => state)

    const [state, setState] = React.useState(initialState)
    const [inputsDisabled, setInputsDisabled] = React.useState(true);
    const [showCancelButton, setShowCancelButton] = React.useState(false);
    const [feedbackVisible, setFeedbackVisible] = React.useState(false);

    const getSetorSelected = (filter) => {
        const selectedFilter = filter !== null ? filter : '';
        setState({ ...state, filter: selectedFilter })
        dispatch(actionGetGradeBySetor(selectedFilter));
        setFeedbackVisible(false);
    }

    const createList = (data, filter) => {
        if (!data) return;
        const filtered = filter ? data.filter(({ idSetor }) => idSetor === filter) : data;
        setState((prevState) => ({
            ...prevState,
            listGradeSetor: filtered,
        }));
    }

    const sendConfiguration = async() => {
        console.log(state.listGradeSetor)

        //dispatch(actionUpdateGradeBySetor(state.listGradeSetor))
        try {
            state.listGradeSetor.map((setor) =>
                //console.log(setor.id, setor.idSetor, setor.qtdRaiox),
                dispatch(actionUpdateGradeBySetor({ id: setor.id, idSetor: setor.idSetor, qtdRaiox: setor.qtdRaiox }))
            )
            setFeedbackVisible(true);
        } catch (error) {
            console.error('Erro ao atualizar:');
        }
    };

    const handleUpdateClick = () => {
        setInputsDisabled(false);
        setShowCancelButton(true);
    }

    const handleCancelClick = () => {
        dispatch(actionGetGradeBySetor(state.filter));
        setInputsDisabled(true);
        setShowCancelButton(false);
    }

    const handlerChange = (index, { target: { value } }) => {
        const updatedListGradeSetor = state.listGradeSetor.map((setor, i) => (
            i === index ? { ...setor, qtdRaiox: Number(value) } : setor
        ));
        setState({ ...state, listGradeSetor: updatedListGradeSetor });
    };

    React.useEffect(() => {
        if (state.filter) {
            dispatch(actionGetGradeBySetor(state.filter));
        }
    }, [state.filter]);

    React.useEffect(() => {
        if (state.filter) {
            createList(getGradeBySetor.data, state.filter);
        }
    }, [getGradeBySetor.data, state.filter]);

return (
    <BaseLayout>
        <div className="container">
            <div className="row">
                <div className="col-4 mb-20">
                    <FilterSetorMonitora cb={getSetorSelected} />
                </div>
                <div className="mt-20">
                    <FeedbackConfiguracoes visible={feedbackVisible} />
                </div>
            </div>
        </div>
        <div className="container" style={{display: 'flex', justifyContent: 'flex-end', gap: '1rem'}}>
            {showCancelButton && (
                <Actions
                    handleCancelClick={handleCancelClick}
                    callbackConfirm={sendConfiguration}
                    loading={loading}
                />
            )}
            <Button id="" size="sm" variant="primary" onClick={handleUpdateClick}>
                Atualizar Parametros
            </Button>
        </div>
        <div className="container mt-20 mb-40" style={{ display: 'flex', flexWrap: 'wrap', overflowY: 'auto', maxHeight: '400px' }}>
            {state.listGradeSetor ? (
                state.listGradeSetor.length > 0 ? (
                    state.listGradeSetor.map((setor, index) => (
                        <div key={index} className="col-sm-12 col-lg-3 col-md-6 mb-20">
                            <Input
                                onChange={(e) => handlerChange(index, e)}
                                type="number"
                                value={setor.qtdRaiox}
                                label={`${setor.inicio.slice(0, 5)} - ${setor.fim.slice(0, 5)}`}
                                size="sm"
                                disabled={inputsDisabled}
                            />  
                        </div>
                    ))
                ) : (
                    <p>Selecione um Canal de Inspecao para carregar os dados da pagina...</p>
                )
            ) : (
                <p>Aguardando resposta do servidor...</p>
            )}
        </div>
    </BaseLayout>
    );
};
export default PageConfigHorariosGrade;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import acesso from './services';

interface IRequestGetForgotPass {
   email: string,
  contato: string
}

interface IStateForgotPass {
  status: "" | "success" | "failed" | "loading",
  feedbackError?: any
}

export const actionGetForgotPass: any = createAsyncThunk(
  "GET_FORGOTPASS",
  async (params: IRequestGetForgotPass) => {
    const { data } = await acesso(params)
    return data
  }
)

const forgotPassSlice = createSlice({
  name: 'GET_FORGOTPASS',
  initialState: <IStateForgotPass>{
    status: '',
    feedbackError: ''
  },
  reducers: {},
  extraReducers: {
    [actionGetForgotPass.pending]: (state) => {
      state.status = 'loading'
    },
    [actionGetForgotPass.fulfilled]: (state, payload ) => {
      state.status = 'success'
    },
    [actionGetForgotPass.rejected]: (state, payload ) => {
      state.status = 'failed'
      state.feedbackError = payload.error.message
    }
  },
})

export default forgotPassSlice.reducer


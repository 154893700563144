import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import download from "../../../helpers/download";
import { getRptLogPortico, IRequestGetRptLogPortico } from "./services";

const initialState: any = {
  status: "",
  noContent: true
};

export const actionGetRptLogPortico: any = createAsyncThunk<any, IRequestGetRptLogPortico>(
  "ACTION_GET_RPT_LOG_PORTICO",
  async (data) => {
    console.log(data.intervalo)
    const result = await getRptLogPortico(data);

    download(result, { ext: data.reportType, fileName: `relatorio-log-portico` });

    return result;
  }
);

const sliceGetRptLogPortico = createSlice({
  name: "GET_RPT_LOG_PORTICO",
  initialState,
  reducers: {},
  extraReducers: {
    [actionGetRptLogPortico.pending]: (state) => {
      state.status = "loading";
    },
    [actionGetRptLogPortico.fulfilled]: (state, { payload: { status } }) => {
      state.status = "success";
      state.noContent = status === 204;
    },
    [actionGetRptLogPortico.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default sliceGetRptLogPortico.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getGradeBySetor } from './services'
import { TGradeBySetor } from './types'

const initialState: any = {
  status: "",
  data: []
}

export const actionGetGradeBySetor = createAsyncThunk<TGradeBySetor, number>(
    "ACTION_GET_GRADE_SETOR",
    async (idSetor) => {
        const result = await getGradeBySetor(idSetor)
        //console.log(result)
        return result
    }
)

const sliceGetGradeBySetor = createSlice({
  name: "GET_GRADE_SETOR",
  initialState,
  reducers: {},
  extraReducers: {
    [`${actionGetGradeBySetor.pending}`]: (state) => {
      state.status = "loading"
    },
    [`${actionGetGradeBySetor.fulfilled}`]: (state, { payload }) => {
      state.status = "success"
      state.data = payload
    },
    [`${actionGetGradeBySetor.rejected}`]: (state) => {
      state.status = "error"
    },
  }
})
export default sliceGetGradeBySetor.reducer
//export const { actionSelectEntradaModulo, actionResetModulos } = sliceGetModuloByCentral.actions
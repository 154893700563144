import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { updateGradeBySetor } from './services'
import { TUpdateGrade } from './types';

const initialState: any = {
  status: "",
  data: [],
  msgError: ""
}

export const actionUpdateGradeBySetor: any = createAsyncThunk<any, TUpdateGrade>(
    "ACTION_UPDATE_GRADE_BY_SETOR",
    async (data) => {
        //console.log(data.id)
        const result = await updateGradeBySetor(data.id, data)
        //console.log(data.id)
        return result
    }
)

const sliceUpdateGradeBySetor = createSlice({
  name: "UPDATE_CONFIG",
  initialState,
  reducers: {
    actionResetStateUpdateGradeBySetor: (state) => {
      state.status = ""
      state.msgError = ""
    }
  },
  extraReducers: {
    [actionUpdateGradeBySetor.pending]: (state) => {
      state.status = "loading"
    },
    [actionUpdateGradeBySetor.fulfilled]: (state, { payload }) => {
      state.status = "success"
      state.data = payload
    },
    [actionUpdateGradeBySetor.rejected]: (state, { error: { message } }) => {
      state.status = "error"
      state.msgError = message
    },
  }
})

export default sliceUpdateGradeBySetor.reducer
export const { actionResetStateUpdateGradeBySetor } =
  sliceUpdateGradeBySetor.actions;
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../helpers/user";
import factoryOptionsFields from "../../../helpers/factory-fields";
import { actionReset } from "../../../features/usuarios-registro/slices";
import { actionRegistroUsuario, actionUpdateUser, actionDeleteUsuario, actionResetUsuario } from "../../../features/usuarios-registro/slices"
import Input from "../../../components/Inputs";
import Select from "../../../components/Select";
import Button, { ButtonGroup } from "../../../components/Button";
import "./styles.scss";

const RegisterUser = ({ callback, user, cancelAction }: any) => {
  const { usuario } = getUser();
  const initialState = {
    id: "",
    requireLoja: false,
    editMode: true,
    formData: {
      nome: "",
      login: "",
      contato: "",
      email: "",
      nivel: ""
    },
  };
  const [state, setState] = React.useState(initialState);

  const dispatch = useDispatch();

  const storeRegisterUser: any = useSelector(
    (state: any) => state
  ).registerUsuario || {};

  const { statusRegister, statusUpdate, statusDelete, statusReset } =
      storeRegisterUser;

  const optionsNiveis = factoryOptionsFields.niveisDeAcesso.filter((item) => {
    if (usuario.nivel === 5) {
      if (item.value === "3" || item.value === "4" || item.value == "0") {
        return item;
      }
    } else {
      return item;
    }
  });

  const loading =
    statusRegister === "loading" ||
    statusUpdate === "loading" ||
    statusReset === "loading" ||
    statusDelete === "loading";

  const disabledSave = state.requireLoja;

  const handlerChange = (ev: any) => {
    ev.preventDefault();
    const { name, value } = ev.currentTarget;

    setState({
      ...state,
      formData: {
        ...state.formData,
        [name]: value,
      },
    });
  };

  const handlerSuccess = () => {
    if (statusRegister === "success" || statusReset === "success" || statusUpdate === "success") {
      setState(initialState);
      callback(true);
      setTimeout(() => dispatch(actionReset()), 1000);
    }
    callback(true)
  };

  const setIsEditable = () => {
    if (user) {
      const {
        nome,
        login,
        contato,
        email,
        nivel,
        id
      } = user;

      setState({
        ...state,
        id,
        editMode: true,
        formData: {
          ...state.formData,
          nome,
          login,
          contato,
          email,
          nivel
        },
      });
    } else {
      setState({
        ...state,
        editMode: false,
        formData: {
          ...state.formData,
        },
      });
    }
  };

  const saveForm = () => {
    if (state.editMode) {
      console.log(state.formData, state.id)
      dispatch(actionUpdateUser({ ...state.formData, id: state.id }));
      cancelAction()
    } else {
      dispatch(actionRegistroUsuario(state.formData));
      cancelAction()
      //console.log(statusUpdate)
    }
  };

  const resetUser = () => {
    const decision = window.confirm(
      `Deseja realmente resetar o login ${state.formData.login}?`
    );
    if (decision) {
      dispatch(actionResetUsuario({ ...state.formData, id: state.id }));
      cancelAction()
    }
  };

  const deleteUser = () => {
    const decision = window.confirm(
      `Deseja realmente excluir o usuário ${state.formData.nome}?`
    );
    if (decision) {
      console.log(state.id)
      dispatch(actionDeleteUsuario(state.id));
      cancelAction()
    }
  };

  //const requiredLoja = () => {
  //  if (!state.formData.nivel) return;
  //  setState({
  //    ...state,
  //    requireLoja: Number(state.formData.nivel) < 5 && !state.formData.idLoja,
  //    formData: {
  //      ...state.formData,
  //    },
  //  });
  //};

  //const getLojas = () => {
  //  if (state.formData.idRegional) {
  //    dispatch(getLojasforUser(state.formData.idRegional));
  //  }
  //};

  React.useEffect(handlerSuccess, [statusRegister, statusReset, statusUpdate]);
  //React.useEffect(getLojas, [state.formData.idRegional]);
  React.useEffect(setIsEditable, [user]);
 // React.useEffect(requiredLoja, [state.formData.nivel, state.formData.idLoja]);
 console.log(state.editMode)
  return (
    <>
      <div
        className={`register-form text-left ${state.editMode && "edit-mode"}`}
      >
        <header>
          {!state.editMode && (
            <p>
              <strong>Dados da conta de usuário</strong>
            </p>
          )}
        </header>
        <section>
          <Select
            size="sm"
            onChange={handlerChange}
            options={optionsNiveis}
            label="Nível de acesso"
            name="nivel"
            value={state.formData.nivel}
          />
          <Input
            size="sm"
            onChange={handlerChange}
            type="text"
            label="Nome completo"
            name="nome"
            value={state.formData.nome}
          />
        </section>
        <section>
          <Input
            size="sm"
            onChange={handlerChange}
            type="text"
            label="Login (Matrícula)"
            name="login"
            value={state.formData.login}
          />
          <Input
            size="sm"
            onChange={handlerChange}
            type="email"
            label="Email"
            name="email"
            value={state.formData.email}
          />
          <Input
            size="sm"
            onChange={handlerChange}
            type="tel"
            label="Contato Telefônico"
            name="contato"
            value={state.formData.contato}
          />
        </section>

        <nav>
          <ButtonGroup>
            <Button size="md" variant="danger" onClick={cancelAction}>
              cancelar
            </Button>
            <Button
              onClick={saveForm}
              disabled={disabledSave}
              size="md"
              variant="primary"
            >
              {loading
                ? "aguarde..."
                : state.editMode
                ? "salvar alterações"
                : "salvar"}
            </Button>
          </ButtonGroup>
        </nav>

        {state.editMode && (
          <div className="actions">
            <Button size="sm" variant="danger" onClick={deleteUser}>
              Excluir Usuário
            </Button>
            <Button size="sm" variant="tertiary" onClick={resetUser}>
              Reset do cadastro
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default RegisterUser;

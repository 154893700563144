import { useLocation } from "react-router-dom";

const PageTitle = () => {
  const { pathname } = useLocation();

    let Titulo;
    let Subtitulo;

    switch (pathname) {
        case "/Canais":
            Titulo = "Monitoramento Geral"
            Subtitulo = "Monitoramento de todos os Canais de Inspecao"
            break

        case "/Detalhamento":
            Titulo = "Monitoramento Detalhado"
            Subtitulo = "Monitoramento detalhado por Canal de Inspecao selecionado"
            break

        case "/cadastrar-usuarios":
            Titulo = "Usuarios"
            Subtitulo = "Inserir, editar e excluir usuarios"
            break

        case "/relatorios":
            Titulo = "Relatorios"
            Subtitulo = "Gerador de relatorios pdf/word/excel"
            break

        case "/configuracoes":
            Titulo = "Grade de utilizacao de Raio-x"
            Subtitulo = "Configuracao de utilizacao de raio-x por periodos"
            break

        default:
            Titulo = "Titulo nao localizado"
            Subtitulo = "-"
            break
    }

  return (
      <div className="monitor-setores" style={{ textAlign: 'center', padding: '0 1rem 0 1rem' }}>
          <h2 className="heading--sm  color-primary">
              <span className="paragraph--sm color-secondary">{Subtitulo}</span> | {Titulo}
          </h2>          
    </div>
  );
}

export default PageTitle
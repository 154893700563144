import React from "react";

function useIsMobile() {
  const [isMobile, setisMobile] = React.useState(false);

  React.useEffect(() => {
    if(window.innerWidth < 760) {
      setisMobile(true)
    }
  });

  return isMobile;
}

export default useIsMobile;
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionReset } from '../../../features/usuarios-registro/slices';
import { mapStatus } from '../../../helpers/factory-fields';
import { mapNiveisDeAcesso } from '../../../helpers/factory-fields';
import RegisterUser from '../RegisterUser';
import './styles.scss';

const ListUsers = ({ callback, cancelAction }: any) => {
  const { data, status }: any = useSelector((state: any) => state).listaUsuarios //listUsuarios
  const { statusDelete}: any = useSelector((state:any) => state).registerUsuario || {};
  const [state, setState] = React.useState({ edit: 0 })
  const dispatch = useDispatch()
  const setEditMode = (id: any) => { 
      setState({ edit: id })
      console.log("setEditMode: ", state)
  }

  const ItemsReadOnly = ({ data }: any) => {
    const { login, dataCadastro, ativo } = data;
    //console.log(login, dataCadastro, ativo)
    return (
      <div className='text-left'>
        {/*<p><strong>Loja:</strong> {loja.nome}</p>*/}
        <p><strong>Login:</strong> {login}</p>
        <p><strong>Data de Cadastro:</strong> {new Date(dataCadastro).toLocaleDateString("pt-br")}</p>
        <p><strong>Status:</strong> {mapStatus[ativo]}</p>
      </div>
    )
  }

  const resetListAfterDelete = () => {
    if(statusDelete === "success") {
      setTimeout(() => callback(true), 1000)
      setTimeout(() => dispatch(actionReset()), 1000)
    }
  }

  React.useEffect(resetListAfterDelete, [statusDelete])

  return (
    <div className='search-list' style={{marginBottom: '0.5rem'}}>
      {(data.length === 0 && status === "success") && <strong>Nenhum usuário cadastrado</strong>}
      {
        data.length > 0 && data.map((item: any) => {
          const { nome, nivel, ativo, id } = item;
          const isEditMode = state.edit === id;
          //console.log(id)
          return (
            <button
              onClick={() => setEditMode(id)}
              disabled={state.edit !== 0 && !isEditMode}
              className={`item-list ${ativo === 0 ? "danger" : "success"} ${isEditMode ? "edit-mode" : ""}`}
            >
              <header>
                <p><strong>{nome}</strong></p>
                <p>{mapNiveisDeAcesso[nivel]}</p>
              </header>
              {isEditMode ? <RegisterUser cancelAction={cancelAction} user={item} callback={callback} /> : <ItemsReadOnly data={item} />}
            </button>
          )
        })
      }
    </div>
  )
}
export default ListUsers
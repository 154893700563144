import Select from "../../Select"
import factoryFields from "../../../helpers/factory-fields"
import React from "react"
import { useAppSelector } from "../../../hooks/useAppSelector"
import RptLogRaiox from "../RptLogRaiox"
import RptLogPortico from "../RptLogPortico"
import RptLogSistema from "../RptLogSistema"
//import RptLogSistema from "../RptLogSistema"

const typesReport =  {
  "RptLogRaiox": <RptLogRaiox />,
  "RptLogPortico": <RptLogPortico />,
  "RptLogSistema": <RptLogSistema />,
}

const SelectTypeReport = () => {
  const [state, setState] = React.useState("")

    const {
      getRptLogRaiox,

    } = useAppSelector((state) => state);

    //if(!lojaSelected) {
    //  return (
    //    <div className="container">
    //      <strong>Para iniciar selecione uma loja</strong>
    //    </div>
    //  );
    //}

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 co-sm-12 mb-20">
      <div className="mb-20">
        {/* {
        (getRptPtosPanorama.noContent && getRptPtosPanorama.status === "success")
        && <Alert type="success">Nenhum relatório encontrado</Alert>
        } */}
      </div>
        <Select
          options={factoryFields.reportsBySetor}
          label="Escolha o tipo de relatório"
          size="sm"
          onChange={(ev) => setState(ev.target.value)}
        />
      </div>
      {typesReport[state]}
    </div>
  );
}

export default SelectTypeReport
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionGetForgotPass } from "../../features/login-forgot/slices";
import Button from "../../components/Button";
import Input from "../../components/Inputs";
import "../PageLogin/_styles.scss";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import IkoneLogo from "../../assets/ikone.png";

interface IStatePageForgotPass {
  email: string,
  contato: string
}

const PageForgotPass = () => {
  const dispatch = useAppDispatch();
  const { getForgotPass } = useAppSelector(state => state)
  const navegate = useNavigate();

  const initialState: IStatePageForgotPass = {
    email: "",
    contato: ""
  };

  const [state, setState] = React.useState(initialState);

  const handlerForgotPass = (ev: React.FormEvent<HTMLInputElement>) => {
    ev.preventDefault();
    dispatch(actionGetForgotPass(state));
  };

  const handlerChange = (ev: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = ev.currentTarget;
    setState({
      ...state,
      [name]: value
    })
  };

  const handlerCancelar = (ev: React.FormEvent<HTMLInputElement>) => {
    ev.preventDefault();
    navegate("/");
  };

  React.useEffect(() => {
    if (getForgotPass.status === "") return;

    //if (getForgotPass.status === "failed") {
    //  alert(getForgotPass.feedbackError);
    //}

    if (getForgotPass.status === "success") {
        alert("Caso o usuario exista no sistema, uma chave de acesso sera enviada ao email informado")
        //navegate("/");
    }
  }, [getForgotPass]);

  return (
    <div className="page-login">
      <form className="form-login">
        <h1 className="text-center heading--md mb-10">Esqueci a Senha</h1>
        <div className="form-login__inputs">
          <Input
            type="text"
            name="email"
            label="Email"
            size="sm"
            placeholder=""
            onChange={handlerChange}
          />
          <Input
            type="text"
            name="contato"
            label="Contato (apenas numeros)"
            size="sm"
            placeholder=""
            onChange={handlerChange}
          />          
        </div>
        <div className="row mb-40">
            <div className="container__button col-sm-6">
                <Button size="sm" variant="primary" onClick={handlerForgotPass} loading={getForgotPass.status === "loading"}>Enviar Chave</Button>    
            </div>
            <div className="container__button col-sm-6">
                <Button size="sm" variant="secondary" onClick={handlerCancelar} >Cancelar/Voltar</Button>    
            </div> 
        </div>        
      </form>
      
      <div className="page-login__footer">
        <p>desenvolvido por</p>
        <img src={IkoneLogo} alt="Logotipo da Ikone"/>
      </div>
    </div>
  );
};
export default PageForgotPass;
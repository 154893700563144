import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import acesso from './services';

interface IRequestGetAtivarAcesso {
  email: string,
  key: string,
  pass: string
}

interface IStateAtivarAcesso {
  //user: object,
  status: "" | "success" | "failed" | "loading",
  feedbackError?: any
}

export const actionGetAtivarAcesso: any = createAsyncThunk(
  "GET_ATIVARACESSO",
  async (params: IRequestGetAtivarAcesso) => {
    const { data } = await acesso(params)
    return data
  }
)

const ativarAcessoSlice = createSlice({
  name: 'GET_ATIVARACESSO',
  initialState: <IStateAtivarAcesso>{
    //user: {},
    status: '',
    feedbackError: ''
  },
  reducers: {},
  extraReducers: {
    [actionGetAtivarAcesso.pending]: (state) => {
      state.status = 'loading'
    },
    [actionGetAtivarAcesso.fulfilled]: (state, payload ) => {
      state.status = 'success'
    },
    [actionGetAtivarAcesso.rejected]: (state, payload ) => {
      state.status = 'failed'
      state.feedbackError = payload.error.message
    }
  },
})

export default ativarAcessoSlice.reducer


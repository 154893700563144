import "./styles.scss";
interface IProps {
  children?: any,
  style?: Object,
  className?: string,
  variant?: string
  shadow?: boolean
}
const Card = ({ children, style, className, variant, shadow = true }: IProps) => {
  return (
    <div className={`card ${className} variant--${variant} ${!shadow && "no-shadow"}`} style={style}>
      {children}
    </div>
  )
}

export default Card
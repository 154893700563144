import request from "../../helpers/request";

interface IRequestRegionais {
  id: number;
  nivel: number;
}

export const getRegionais = async ({ nivel, id }: IRequestRegionais) => {
  let url;

  if (nivel === 6) {
    url = `/Regional/GetRegionaisIdGerente?idGerente=${id}`;
  }

  if (nivel == 7) {
    url = `/Regional`;
  }

  try {
    const result = await request({ url });
    return result;
  } catch (err) {
    throw err;
  }
};

interface IRequestGetLojas {
  idCoordenador?: string;
  idRegional?: string;
}

export const getLojas = async ({
  idCoordenador,
  idRegional,
}: IRequestGetLojas) => {
  const apiLojas = idCoordenador
    ? `GetLojasIdCoordenacao?idCoord=${idCoordenador}`
    : `GetLojasIdRegional?idReg=${idRegional}`;
  const url = `${process.env.REACT_APP_API}/Loja/${apiLojas}`;

  try {
    const result = await request({ url });
    return result;
  } catch (err) {
    throw err;
  }
};

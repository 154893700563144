import React, { useEffect, useState } from "react";
import { actionGetRptLogSistema } from "../../../features/relatorios/rptLogSistema/slices";
import factoryFields from "../../../helpers/factory-fields";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Button from "../../Button";
import Input from "../../Inputs";
import Select from "../../Select";

const RptLogSistema = () => {
  const [state, setState] = useState<any>();
  const dispatch = useAppDispatch();
  const { getRptLogSistema } = useAppSelector((state) => state);

  // useEffect(() => {
  //   dispatch(actionGetRptHistoricosDispositivos());
  // }, []);

  const handlerChange = (e: any) => {
    const { name, value } = e.currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handlerReport = () => dispatch(actionGetRptLogSistema(state))
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 co-sm-12">
          <div className="mb-20">
            <Select
              size="sm"
              label="Formato do arquivo"
              options={factoryFields.typesReport}
              onChange={handlerChange}
              name="reportType"
            />
            <Input
              type="date"
              size="sm"
              label="data Início"
              name="dataIni"
              onChange={handlerChange}
            />
            <Input
              type="date"
              size="sm"
              label="data Fim"
              name="dataFim"
              onChange={handlerChange}
            />
          </div>
          <div className="mb-30">
            <Button size="sm" onClick={handlerReport} loading={getRptLogSistema.status === "loading"}>
                Baixar Relatório
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RptLogSistema
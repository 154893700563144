import request from "../../../helpers/request";

export interface IRequestGetRptLogPortico {
  idSetor: string;
  reportType: string;
  dataIni: string;
  dataFim: string;
  intervalo: number;
}

export const getRptLogPortico = async ({
  idSetor,
  reportType,
  dataIni,
  dataFim,
  intervalo
}: IRequestGetRptLogPortico) => {
    console.log(idSetor, reportType, dataIni, dataFim, intervalo)
  const _reportType = reportType ? `reportType=${reportType}` : ""
  const _dataIni = dataIni ? `&dataIni=${dataIni}` : ""
  const _dataFim = dataFim ? `&dataFim=${dataFim}` : ""
  const _idSetor = idSetor ? `&idSetor=${idSetor}` : ""
  const _intervalo = intervalo ? `&intervalo=${intervalo}` : ""

  const str = `${_reportType}${_idSetor}${_dataIni}${_dataFim}${_intervalo}`
  const url = `${process.env.REACT_APP_API}/Relatorio/RptLogPortico?${str}`;
  const result = await request({ url, responseType: "blob" });
  return result;
};

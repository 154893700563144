import request from '../../helpers/request'

interface IRequestAtivarAcesso {
  email: string,
  key: string,
  pass: string
}

const acesso = async (params: IRequestAtivarAcesso) => {

  const url = `${process.env.REACT_APP_API}/Usuario/AtivarAcesso?email=${params.email}&chave=${params.key}&senha=${params.pass}`
  const result = await request({ url, noProtect: true }) // request para api

  if (result.data === 'Ativado com sucesso') {
      return result.data
  } else {
    throw new Error(result.data)
  }
}

export default acesso
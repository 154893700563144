import { AxiosResponse } from "axios";

enum ETypesFile {
  "word" = "doc",
  "xls" = "xls",
  "pdf" = "pdf"
}
const validateContent = ({ status, data }: AxiosResponse) => {
  if(status === 200) {
    return data
  }
  return null
};

const download = (data, { ext, fileName }) => {
  const blob = validateContent(data);

  if (blob) {
    const date = new Date().getTime();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${fileName}-${date}.${ETypesFile[ext]}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
};

export default download;

import React, { useContext } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Button, { Link } from "../../Button";
import "./styles.scss";

export const ContextMenu = React.createContext(null as any);

const FilterAndMenu = () => {
  const [state, setState] = React.useState({ levelsActives: [] } as any);

  const toggleMenu = (ev) => {
    const { currentTarget: { id } } = ev
    const exists = state.levelsActives.findIndex((item) => item === id)
    const levelsActives = exists === -1 ? [...state.levelsActives, id] : state.levelsActives.filter(item => item !== id)
    setState({
      ...state,
      levelsActives,
    });
  };

  const openMenu = (idMenu: string) =>
    state.levelsActives.find((item) => item === idMenu);
  //const {
  //  getLojas: { lojaSelected },
  //} = useAppSelector((state) => state);
  //const disbleMenuMonitoria = !lojaSelected ? "disabled" : "";

  return (
    <div className="wrapper-filter">
      {/*<DadosLojas />*/}
      <ul className="accordion">
        <li className={`accordion__item`}>
          <Button
            variant="tertiary"
            size="sm"
            id="menu-pts-monitoria"
            onClick={toggleMenu}
          >
            Setores Monitorados
            <i
              className={`fa-solid fa-chevron-${
                openMenu("menu-pts-monitoria") ? "up" : "down"
              }`}
            ></i>
          </Button>
          {/*{!lojaSelected && (*/}
          {/*  <span className="text-helper">Antes selecione a loja desejada</span>*/}
          {/*)}*/}
        </li>
        <ul className={`accordion ${openMenu("menu-pts-monitoria") && "active"}`}>
          <li className="accordion__item"><Link to="/Setores" variant="tertiary" size="sm">Setores</Link></li>
          <li className="accordion__item"><Link to="/Detalhamento" variant="tertiary" size="sm">Visão Detalhada</Link></li>
          <li className="accordion__item"><Link to="/configuracoes" variant="tertiary" size="sm">Configurações</Link></li>
          {/*<li className="accordion__item"><Link to="/pontos-de-monitoria/alertas" variant="tertiary" size="sm">Alertas</Link></li>*/}
          <li className="accordion__item disabled"><Link to="/pontos-de-monitoria/relatorios" variant="tertiary" size="sm">Relatórios</Link></li>
          <li className="accordion__item disabled">
            <Button
              variant="tertiary"
              size="sm"
              id="menu-configs"
              onClick={toggleMenu}
            >
              Configurações
              <i
                className={`fa-solid fa-chevron-${
                  openMenu("menu-configs") ? "up" : "down"
                }`}
              ></i>
            </Button>
            <ul className={`accordion ${openMenu("menu-configs") && "active"}`}>
              <li className="accordion__item">
                <Link to="/pontos-de-monitoria/configurar" variant="tertiary" size="sm">Pontos de monitoramento</Link>
              </li>
              <li className="accordion__item disabled">
                <Link to="/config-ponto-de-monitoria" variant="tertiary" size="sm">Parâmetros dos dispositivos</Link>
              </li>
              <li className="accordion__item disabled">
                <Link to="/config-ponto-de-monitoria" variant="tertiary" size="sm">Envio de alertas</Link>
              </li>
            </ul>
          </li>
          <li className="accordion__item disabled"><Link to="/pontos-de-monitoria/planta-baixa" variant="tertiary" size="sm">Planta Baixa</Link></li>
          <li className="accordion__item disabled">
            <Button variant="tertiary" size="sm" id="menu-docs-avcb" onClick={toggleMenu}>
              Documentação AVCB
              <i className={`fa-solid fa-chevron-${openMenu("menu-docs-avcb") ? "up" : "down"}`}></i>
            </Button>
            <ul className={`accordion ${openMenu("menu-docs-avcb") && "active"}`}>
              <li className="accordion__item disabled">
                <Link to="/config-ponto-de-monitoria" variant="tertiary" size="sm">Status</Link>
              </li>
              <li className="accordion__item">
                <Link to="/config-ponto-de-monitoria disabled" variant="tertiary" size="sm">validação de documentos</Link>
              </li>
            </ul>
          </li>
        </ul>
      </ul>
    </div>
  );
};

const OpenedMenu = ({ isOpen }) => {
  const context = useContext(ContextMenu);
  return (
    <aside className={`menu ${isOpen ? "" : "closed"}`}>
      <ul className="accordion">
        <li className="accordion__item">
            <Link to="/Canais" variant="tertiary" size="sm">
              <i className="fa-solid fa-clipboard"></i>
              Canais de Inspeção
            </Link>
         </li>
        <li className="accordion__item">
            <Link to="/Detalhamento" variant="tertiary" size="sm">
              <i className="fa-solid fa-list-alt"></i>
                Detalhamento
            </Link>
         </li>
        <li className="accordion__item">
          <Link to="/cadastrar-usuarios" variant="tertiary" size="sm">
            <i className="fa-solid fa-sheet-plastic"></i>
            Cadastrar usuários
          </Link>
        </li>
        <li className="accordion__item">
          <Link to="/relatorios" variant="tertiary" size="sm">
            <i className="fa-solid fa-sheet-plastic"></i>
            Relatórios
          </Link>
        </li>
        <li className="accordion__item disabled">
          <Link to="/dashboard" variant="tertiary" size="sm">
            <i className="fa-solid fa-chart-simple"></i>
            Dashboard
          </Link>
        </li>
        <li className="accordion__item">
          <Link to="/configuracoes" variant="tertiary" size="sm">
            <i className="fa-solid fa-gear"></i>
            Configurações
          </Link>
        </li>
      </ul>
    </aside>
  );
};

const Menu = ({ isOpen }: any) => <OpenedMenu isOpen={isOpen} />;

export default Menu;

import request from "../../../helpers/request";

export interface IRequestGetRptLogRaiox {
  idSetor: string;
  reportType: string;
  dataIni: string;
  dataFim: string;
}

export const getRptLogRaiox = async ({
  idSetor,
  reportType,
  dataIni,
  dataFim,
}: IRequestGetRptLogRaiox) => {
    console.log(idSetor, reportType, dataIni, dataFim)
  const _reportType = reportType ? `reportType=${reportType}` : ""
  const _dataIni = dataIni ? `&dataIni=${dataIni}` : ""
  const _dataFim = dataFim ? `&dataFim=${dataFim}` : ""
  const _idSetor = idSetor ? `&idSetor=${idSetor}` : ""

  const str = `${_reportType}${_idSetor}${_dataIni}${_dataFim}`
  const url = `${process.env.REACT_APP_API}/Relatorio/RptLogRaiox?${str}`;
  const result = await request({ url, responseType: "blob" });
  return result;
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPtosMonitor } from './services'
import { TPtosMonitor } from './types'

const initialState: any = {
  status: "",
  data: []
}

export const actionGetPontosMonitor = createAsyncThunk<TPtosMonitor>(
    "ACTION_GET_PONTOS_MONITOR",
    async () => {
        const result = await getPtosMonitor()
        //console.log(result)
        return result
    }
)

const sliceGetPontosMonitor = createSlice({
  name: "GET_PONTOS_MONITOR",
  initialState,
  reducers: {},
  extraReducers: {
    [`${actionGetPontosMonitor.pending}`]: (state) => {
      state.status = "loading"
    },
    [`${actionGetPontosMonitor.fulfilled}`]: (state, { payload }) => {
      state.status = "success"
      state.data = payload
    },
    [`${actionGetPontosMonitor.rejected}`]: (state) => {
      state.status = "error"
    },
  }
})
export default sliceGetPontosMonitor.reducer
//export const { actionSelectEntradaModulo, actionResetModulos } = sliceGetModuloByCentral.actions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import download from "../../../helpers/download";
import { getRptLogRaiox, IRequestGetRptLogRaiox } from "./services";

const initialState: any = {
  status: "",
  noContent: true
};

export const actionGetRptLogRaiox: any = createAsyncThunk<any, IRequestGetRptLogRaiox>(
  "ACTION_GET_RPT_LOG_RAIOX",
  async (data) => {
    console.log(data.idSetor)
    const result = await getRptLogRaiox(data);

    download(result, { ext: data.reportType, fileName: `relatorio-log-raiox` });

    return result;
  }
);

const sliceGetRptLogRaiox = createSlice({
  name: "GET_RPT_LOG_RAIOX",
  initialState,
  reducers: {},
  extraReducers: {
    [actionGetRptLogRaiox.pending]: (state) => {
      state.status = "loading";
    },
    [actionGetRptLogRaiox.fulfilled]: (state, { payload: { status } }) => {
      state.status = "success";
      state.noContent = status === 204;
    },
    [actionGetRptLogRaiox.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default sliceGetRptLogRaiox.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getLojas } from '../regional-e-loja/services';
import registroUsuario, { deleteUsuario, resetUser, updateUsuario } from './services';

interface IStateRegisterUsuario {
  statusRegister: "" | "success" | "failed" | "loading",
  statusDelete: "" | "success" | "failed" | "loading",
  statusUpdate: "" | "success" | "failed" | "loading",
  statusReset: "" | "success" | "failed" | "loading",
  //lojasByUser: [],
  feedbackError: string
}

interface IRequestUserAddAndUpdate {
  nome: string,
  login: string,
  contato: string,
  email: string,
  nivel: number,
  id?: number
}

//n�o cadastra os dados do contato via front nem via API
//ok
export const actionRegistroUsuario: any = createAsyncThunk(
  "REGISTER_USUARIO",
  async (data: IRequestUserAddAndUpdate) => {
    //delete data.id
    console.log(data)
    const request = await registroUsuario({ ...data })
    return request
  }
)

//ok
export const actionUpdateUser: any = createAsyncThunk(
  "UPDATE_USUARIO",
  async (data: IRequestUserAddAndUpdate) => {
    console.log(data)
    const request = await updateUsuario({ ...data })
    return request
  }
)

export const getLojasforUser:any = createAsyncThunk(
  "GET_LOJAS_BY_USER_EDIT",
  async (idRegional: string) => {
    const { data } = await getLojas({ idRegional })
    return data
  }
)
//ok
export const actionDeleteUsuario: any = createAsyncThunk(
  "DELETE_USER",
  async (id: string) => {
    console.log(id)
    const { data } = await deleteUsuario(id)
    return data;
  }
)
//ok
export const actionResetUsuario: any = createAsyncThunk(
  "RESET_USER",
  async (data: IRequestUserAddAndUpdate) => {

    const request = await resetUser({ ...data })
    return request
  }
)

const registroUsuarioSlice = createSlice({
  name: "REGISTER_USUARIO",
  initialState: <IStateRegisterUsuario> {
    statusRegister: "",
    statusDelete: "",
    statusUpdate: "",
    statusReset: "",
    //lojasByUser: [],
    feedbackError: ""
  },
  reducers: {
    actionReset: (state) => {
      console.log("passei aqui", actionReset)
      state.statusRegister = "";
      state.feedbackError = "";
      state.statusDelete =  "";
      state.statusUpdate =  "";
      state.statusReset =  "";
    }
  },
  extraReducers: {
    [actionRegistroUsuario.pending]: (state) => {
      state.statusRegister = "loading"
      state.feedbackError = ""
    },
    [actionRegistroUsuario.fulfilled]: (state, { payload }) => {
      state.statusRegister = "success"
      state.feedbackError = ""
    },
    [actionRegistroUsuario.rejected]: (state, { error }) => {
      state.statusRegister = "failed"
      state.feedbackError = error.message
    },

    [actionUpdateUser.pending]: (state) => {
      state.statusUpdate = "loading"
      state.feedbackError = ""
    },
    [actionUpdateUser.fulfilled]: (state, { payload }) => {
      state.statusUpdate = "success"
      state.feedbackError = ""
    },
    [actionUpdateUser.rejected]: (state, { error }) => {
      state.statusUpdate = "failed"
      state.feedbackError = error.message
    },

    [actionDeleteUsuario.pending]: (state) => {
      state.statusDelete = "loading"
      state.feedbackError = ""
    },
    [actionDeleteUsuario.fulfilled]: (state, { payload }) => {
      state.statusDelete = "success"      
      state.feedbackError = ""
    },
    [actionDeleteUsuario.rejected]: (state, { error }) => {
      state.statusDelete = "failed"
      state.feedbackError = error.message
    },

    [actionResetUsuario.pending]: (state) => {
      state.statusReset = "loading"
      state.feedbackError = ""
    },
    [actionResetUsuario.fulfilled]: (state, { payload }) => {
      state.statusReset = "success"
      state.feedbackError = ""
    },
    [actionResetUsuario.rejected]: (state, { error }) => {
      state.statusReset = "failed"
      state.feedbackError = error.message
    },

    [getLojasforUser.fulfilled]: (state, { payload }) => {
      //state.lojasByUser = payload
      state.feedbackError = ""
    },

  }
})
export const { actionReset } = registroUsuarioSlice.actions;
export default registroUsuarioSlice.reducer
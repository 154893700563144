import request from "../../helpers/request"
import { TUpdateGrade } from "./types"

const DTO = (data: TUpdateGrade) => {
    const {
        id,
        idSetor,
        qtdRaiox
    } = data

    return {
        id: Number(id),
        idSetor: Number(idSetor),
        qtdRaiox: Number(qtdRaiox)
    }
}

export const updateGradeBySetor = async (id: number, props: TUpdateGrade) => {
    console.log(id)
    const { idSetor, qtdRaiox } = DTO(props);
    const url = `${process.env.REACT_APP_API}/GradeRaiox/${id}?idSetor=${idSetor}&qtdRaiox=${qtdRaiox}`
    const data = DTO(props)
    const result = await request({ url, method: "put", data, headers: { 'Content-Type': 'application/json' } })
  
    if(result.data.toLowerCase() === "ok") {
        return result
    } else {
        throw new Error(result.data)
    }
}
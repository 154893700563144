import request from "../../../helpers/request";

export interface IRequestGetRptLogSistema {
  reportType: string;
  dataIni: string;
  dataFim: string;
}

export const getRptLogSistema = async ({
  reportType,
  dataIni,
  dataFim,
}: IRequestGetRptLogSistema) => {
  const _reportType = reportType ? `reportType=${reportType}` : ""
  const _dataIni = dataIni ? `&dataIni=${dataIni}` : ""
  const _dataFim = dataFim ? `&dataFim=${dataFim}` : ""

  const str = `${_reportType}}${_dataIni}${_dataFim}`
  const url = `${process.env.REACT_APP_API}/Relatorio/RptLogSistema?${str}`;
  const result = await request({ url, responseType: "blob" });
  return result;
};

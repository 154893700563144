import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSetor } from "./services";
import { TSetor } from "./types";

interface IInitialState {
  status: "error" | "success" | "loading" | "",
  grupoSetorSelected: any,
  data: Array<TSetor>,
}

const initialState = {} as IInitialState;

export const actionGetGrupoSetor: any = createAsyncThunk<TSetor>(
    "ACTION_GET_GRUPO_SETOR",
    async () => {
        const result = await getSetor()
        return result
    }
);
//export const actionGetGrupoSetor: any = createAsyncThunk<any, string>(
//  "ACTION_GET_GRUPO_SETOR",
//  async (_: any) => {
//    return getSetor();
//  }
//);
//export const actionGetPontosMonitor = createAsyncThunk<TPtosMonitor>(
//    "ACTION_GET_PONTOS_MONITOR",
//    async () => {
//        const result = await getPtosMonitor()
//        return result
//    }
//)

const sliceGetGrupoSetor = createSlice({
  name: "GET_GRUPO_SETOR",
  initialState,
  reducers: {
    actionSelectGrupoSetor: (state, { payload }) => {
      state.grupoSetorSelected = state.data.find(
        ({ id }) => id === Number(payload)
      );
    },
    actionResetGrupoSetor: (state) => {
      state.status = ""
      state.grupoSetorSelected = null;
      state.data = []
    },
  },
  extraReducers: {
    [actionGetGrupoSetor.pending]: (state) => {
      state.status = "loading";
    },
    [actionGetGrupoSetor.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.data = payload;
    },
    [actionGetGrupoSetor.rejected]: (state) => {
      state.status = "error";
    },
  },
});

export default sliceGetGrupoSetor.reducer;
export const { actionSelectGrupoSetor, actionResetGrupoSetor } = sliceGetGrupoSetor.actions;

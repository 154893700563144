import BaseLayout from "../../components/Layout";
//import RptLogSistema from "../../components/Relatorios/RptLogSistema";

const PageRelatorios = () => {
  return (
    //<BaseLayout>
    //  <div className="container mb-20">
    //    <div className="row">
    //      <div className="col-12">
    //        <h3>Relatório de Log's do Sistema</h3>
    //      </div>
    //    </div>
    //  </div>
    // <RptLogSistema />
    //</BaseLayout>
    <p>nada</p>
  );
};

export default PageRelatorios

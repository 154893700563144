import React from "react";
import Button, { ButtonGroup } from "../../components/Button";
import BaseLayout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { actionListaUsuarios } from "../../features/usuarios-lista/slices"
import { actionReset } from "../../features/usuarios-registro/slices";
import { getUser } from "../../helpers/user";
import Alert from "../../components/Alert";
import ListUsers from "./ListUsers";
import RegisterUser from "./RegisterUser";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import "./styles.scss";

const PageCadastroUser = () => {
    const initialState = {
        register: false,
        search: false,
        edit: false,
        //onlyAdm: false,
    };
    const [state, setState] = React.useState(initialState);
    const [showAlert, setShowAlert] = React.useState(true);
    const {
        feedbackError,
        statusRegister,
        statusUpdate,
        statusDelete,
        statusReset,
    } = useAppSelector((state: any) => state).registerUsuario || {};
    const { usuario } = getUser();
    const dispatch = useAppDispatch();

    const handlerSearch = () => {
        dispatch(actionListaUsuarios())
        setState({
            ...state,
            register: false,
            search: true,
        });
        console.log("handlerSearch: ", state)
    };

    const handlerAdd = () => {
        setState({ ...state, register: true, search: false, edit: false });
        console.log("handlerAdd: ", state)
    }
    
    const cancelAction = () => {
        if (state.search) {
            setState({ ...state, search: false });
        }
        if (state.register) {
            setState({ ...state, register: false });
        }
    };
    const callbackListUsers = (done: boolean) => {
        setState({ ...state, search: done});
        console.log("callbacklListUsers: ", state)
    }
    
    const callbackRegisterUser = (done: boolean) => {
        setState({ ...state, register: done });
        console.log("callbackRegisterUsers: ", state)
    }
    //CORREÇÃO DO ACTION RESET
    React.useEffect(() => {
        const reloadPage = () => {
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        };

        if (
            statusRegister === "success" ||
            statusUpdate === "success" ||
            statusDelete === "success" ||
            statusReset === "success" ||
            feedbackError
        ) {
            reloadPage();
        }
    }, [statusRegister, statusUpdate, statusDelete, statusReset, feedbackError]);

    console.log(statusRegister, statusUpdate, statusDelete, statusReset, feedbackError)

    //CORREÇÃO DO ACTION RESET
    React.useEffect(() => {
        dispatch(actionReset());
    }, [statusRegister, statusUpdate, statusDelete, statusReset, feedbackError]);

    return (
        <BaseLayout>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-10">
                            <h3>Cadastro de usuários</h3>
                        </div>
                        <div className="mb-20">
                            <ButtonGroup>
                                <Button size="md" variant="primary" onClick={handlerAdd}>
                                Adicionar
                                </Button>
                                <Button size="md" onClick={handlerSearch} variant="secondary">
                                Lista de Usuários
                                </Button>
                            </ButtonGroup>
                        </div>
                        {state.search && (
                          <ListUsers
                            cancelAction={cancelAction}
                            callback={callbackListUsers}
                          />
                        )}
                        {state.register && (
                          <RegisterUser
                            cancelAction={cancelAction}
                            callback={callbackRegisterUser}
                          />
                        )}

                        {statusRegister === "success" && (
                          <Alert type="success">Cadastro realizado com sucesso!</Alert>
                        )}
                        {statusUpdate === "success" && (
                          <Alert type="success">Cadastro atualizado com sucesso!</Alert>
                        )}
                        {statusDelete === "success" && (
                          <Alert type="success">O usuario foi excluído!</Alert>
                        )}
                        {statusReset === "success" && (
                          <Alert type="success">Reset realizado com sucesso!</Alert>
                        )}
                        {feedbackError && <Alert type="error">{feedbackError}</Alert>}
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
};

export default PageCadastroUser;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUser } from '../../helpers/user';
import listaUsuarios from './services';
import { TUsuariosAll } from './types'

interface IStateListUsuarios {
  status: "" | "success" | "failed" | "loading",
  data: [],
  feedbackError: ""
}

export const actionListaUsuarios: any = createAsyncThunk<TUsuariosAll, any>(
  "LIST_USUARIO",
  async () => {
    const request = await listaUsuarios()
    return request
  }
)

//export const actionListUsersByLoja: any = createAsyncThunk(
//  "LIST_USUARIO_BY_LOJA",
//  async ({ idLoja }: any) => {
//    const request = await listUsersByLoja({ idLoja })
//    return request;
//  }
//)

const listaUsuariosSlice = createSlice({
  name: "LIST_USUARIO",
  initialState: <IStateListUsuarios>{
    status: "",
    data: [],
    feedbackError: ""
  },
  reducers: {
    actionReset: (state) => {
      state.status = "";
      state.feedbackError = "";
    }
  },
  extraReducers: {
    [actionListaUsuarios.pending]: (state) => {
      state.status = "loading"
    },
    [actionListaUsuarios.fulfilled]: (state, { payload }) => {
      state.status = "success"
      state.data = payload
    },
    [actionListaUsuarios.rejected]: (state, { error }) => {
      state.status = "failed"
      state.feedbackError = error.message
    }
  }
})
export const { actionReset } = listaUsuariosSlice.actions;
export default listaUsuariosSlice.reducer
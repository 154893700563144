import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
/*import BreadCrumb from "./BreadCrumb";*/
import Header from "./header";
//import InfoLoja from "./InfoLoja";
import PageTitle from "./PageTitle";
import Menu, { ContextMenu } from "./menu";
import "./styles.scss"
interface TypesLayout {
  children: any;
  className?: any;
}


const BaseLayout = ({ children }: TypesLayout) => {
  const isMobile = useIsMobile()
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const [y, setY] = React.useState(false)

  const handleNavigation = () => {
    setY(() => {
      if(window.scrollY > 8) {
        return true
      } else {
        return false
      }
    })
  }
  
  React.useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation());
  
    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => handleNavigation());
    };
  }, []);

  React.useEffect(() => {
    if(isMobile) {
      setMenuIsOpen(false)
    }
  }, [isMobile])
  
  return (
    <div className="base-layout">
      <ContextMenu.Provider
        value={{ menuIsOpen, setMenuIsOpen: () => setMenuIsOpen(!menuIsOpen) }}
      >
        <Header />
        <Menu isOpen={menuIsOpen} />
      </ContextMenu.Provider>

      <main className="base-layout__main">
        <header className={`base-layout__content-header mb-10 ${y && "active"}`}>
          <div className="container" style={{display: 'flex', justifyContent: 'flex-end'}}>
            <div className="row">
                <PageTitle/>
            </div>
          </div>
        </header>
        {children}
      </main>
    </div>
  );
}

export default BaseLayout;
import request from "../../helpers/request";
import { getUser } from "../../helpers/user";

//interface IListUsersRequest {
//  idLoja: string,
//  idRegional: string,
//  nivel: number,
//  onlyAdm?: boolean
//}

const listaUsuarios = async () => {
  const url = `/Usuario/GetAll`
    
  try {
    const result = await request({ url })
    return result.data
  } catch (err) {
    throw err
  }
}

export default listaUsuarios
import request from '../../helpers/request'

interface IRequestForgotPass {
  email: string,
  contato: string
}

const acesso = async (params: IRequestForgotPass) => {

  const url = `${process.env.REACT_APP_API}/Usuario/ForgotPassword?email=${params.email}&contato=${params.contato}`
  const result = await request({ url, noProtect: true }) // request para api

    if (result.data) {
      return result.data
    } else {
    throw new Error(result.data)
  }
}

export default acesso
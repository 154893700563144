import "./styles.scss"

interface IPropsAlert {
  children: any,
  type?: "error" | "success" | "default"
}

const Alert = ({ children, type = "default" }: IPropsAlert) => {
  return <div className={`alert ${type}`}>{children}</div>;
};

export default Alert;
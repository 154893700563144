import request from "../../helpers/request";

const registroUsuario = async (dataRequest: any) => {
  const url = `/Usuario`;
  console.log(dataRequest)
  const data = dataRequest
  //console.log(data)
  const result = await request({ url, method: "post", data, headers: { 'Content-Type': 'application/json' } })

  //console.log(result)
  if(result.data.toLowerCase() === "ok") {
      return result
  } else {
      throw new Error(result.data)
  }
};

export const updateUsuario = async (dataRequest: any) => {
  const url = `/Usuario/${dataRequest.id}`;
  console.log(dataRequest)
  //const data = dataRequest
  //const { result }= await request({ url, method: "put", data, headers: { 'Content-Type': 'application/json' } })
  const { data } = await request({
    url,
    method: "put",
    data: {
      ...dataRequest,
      //contatoAlerta: "",
      //emailAlerta: "",
      //idLoja: Number(dataRequest.idLoja),
    },
    headers: { 'Content-Type': 'application/json' }
  });
  console.log(data)
  if (data === "ok") {
    return data;
  } else {
      throw new Error(data)
  }
};

export const deleteUsuario = async (id: any) => {
  const url = `/Usuario/Excluir/${id}`;
  const { data } = await request({ url, method: "put" });

  if (data === "ok") {
    return data;
  } else {
    const error = data.split("Erro - ").filter((item: any) => item !== " ");
    throw new Error(error);
  }
};

export const resetUser = async (dataRequest: any) => {
  console.log(dataRequest.id)
  const url = `/Usuario/ResetUsuario/${dataRequest.id}`;
  const { data } = await request({
    url,
    method: "put",
    data: {
      ...dataRequest,
      //contatoAlerta: "",
      //emailAlerta: "",
      //idLoja: Number(dataRequest.idLoja),
    },
  });

  if (data === "ok") {
    return data;
  } else {
    const error = data.split("Erro - ").filter((item: any) => item !== " ");
    throw new Error(error);
  }
};

export default registroUsuario;

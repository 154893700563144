/* eslint-disable react/style-prop-object */
import React from "react";
import "./_styles.scss";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import BaseLayout from "../../components/Layout";
import { Chart } from "react-chartjs-2"
import Card from "../../components/Card"
import { factoryChart, factoryLabels, optionsChart } from "../../helpers/factory-charts"
import Tag from "../../components/Tag";
import Button, { Link } from "../../components/Button";
import Progress from "../../components/Progress";
import ConfigIcon from "../../assets/settings-icon.png";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getUser } from "../../helpers/user";
import { actionGetPontosMonitor } from "../../features/PontosMonitor/slices";
import RegisterGrade from "./ModalGrade"
import ButtonConfigGrade from "../../components/ButtonConfigGrade";

const initialState = {
    openModal: false,
    listSetores: [],
    graficoCards: { datasets: [], labels: [], },
} as any;

let responseSocket;

const contador = {
    num: 0,
}

const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SOCKET}/hubPtosMonitor`, {
        accessTokenFactory: () => getUser().token,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

const PagePerspecGlobal = () => {
    const dispatch = useAppDispatch();
    const { getPontosMonitor } = useAppSelector((state) => state)

    const [state, setState] = React.useState(initialState)

    const openModal = () => setState({ ...state, openModal: true });

    const closeModalRegister = () =>
        setState({ ...state, openModal: !state.openModal });

    const createList = (data) => {
        const listSetores = data;
        console.log(listSetores)
        setState({
            ...state,
            listSetores,
            graficoCards: {
                datasets: [
                    ...factoryChart([8, 12, 7, 5, 13, 12], ["rgb(170, 170, 170)"], "line", "Quantidade equipamentos programados"),
                    ...factoryChart([12, 10, 6, 7, 16, 9], ["#276ef1", "#048848", "#e11900"], "bar", "Quantidade equipamentos ativos")
                ],//rgb(150, 150, 150)
                labels: [11, 12, 13, 14, 15, 16],
            },
            //graficoCards: {
            //    datasets: [
            //        factoryChart([12, 10, 6, 7, 16, 9], ["#276ef1", "#048848", "#e11900"], "bar"),
            //        factoryChart([8, 12, 7, 5, 13, 12], ["black"], "line")
            //    ],
            //    labels: [11, 12, 13, 14, 15, 16],
            //},
        })
    }

    //setState({
    //    ...state,
    //    graficoCards: {
    //        datasets: factoryChart([12,10,6,34,20,15], ["green"], "bar"),
    //        labels: [1,2,3,4,5,6],
    //    },
    //})

    React.useEffect(() => {
        dispatch(actionGetPontosMonitor());
        connection.start().then(() => console.log('connection started'));
    }, []);

    React.useEffect(() => {
        connection.on('SocketPtosMonitor', function (data) {
            responseSocket = data
            contador.num++
            if (contador.num > 1000) {
                contador.num = 1
            }
            console.log(contador)
            createList(responseSocket.setores)
        });

        if (responseSocket) {
            createList(responseSocket.setores)
            console.log("opcao1")
        } else {
            createList(getPontosMonitor.data.setores)
            console.log("opcao2")
        }
        //console.log(responseSocket)
    }, [getPontosMonitor.data, contador.num]);

    //console.log(state.listSetores)
return (
    //<BaseLayout>
    //    <div className="list-pts-monitoria">
    //        {state.listSetores ? (
    //            state.listSetores.length > 0 ? (
    //                state.listSetores.map(
    //                    ({
    //                        setor,
    //                        id,
    //                        nome,
    //                        descricao,
    //                        status,
    //                        trafegoPortico,
    //                        listModuloRxPrt,
    //                        qtdRaioxGrade,
    //                        qtdRaioxAtual
    //                    }) => (
    //                        <div
    //                            className={`card-monitoria ${qtdRaioxAtual === qtdRaioxGrade ? "positive" : (qtdRaioxAtual > qtdRaioxGrade ? "accent" : "negative") }`}
    //                        >
    //                            <div className="card-monitoria__content">
    //                                <header>
    //                                    <div className="topheader">
    //                                        <div className="mb-10">
    //                                            <h1 className="heading paragraph--lg color-secondary">
    //                                                <strong>{nome}</strong>
    //                                            </h1>
    //                                            <p className="paragraph paragraph--sm color-secondary">
    //                                                {descricao}
    //                                            </p>
    //                                        </div>
    //                                    </div>
    //                                </header>
    //                                <div className="contentCardRaiox">
    //                                    <Card>
    //                                        <p className="paragraph paragraph--sm">
    //                                            <strong>Fluxo: </strong>200 <strong>/ Alertas: </strong>10 <strong>/ Aleatorios: </strong>2
    //                                        </p>
    //                                    </Card>
    //                                </div>
    //                                <div className="col-12 contentCardRaiox">
    //                                    <Card>
    //                                        <Chart
    //                                            options={optionsChart}
    //                                            type="bar"
    //                                            data={state.graficoCards}
    //                                        />
    //                                    </Card>
    //                                </div>
    //                                <div className="contentCardRaiox">
    //                                    <Card>
    //                                        <p className="paragraph paragraph--sm mb-10" style={{textAlign: 'center'}}>
    //                                            <strong>RAIO-X:</strong> Ativos/Programados: {qtdRaioxAtual}/{qtdRaioxGrade}
    //                                        </p>
    //                                        <div className="raioxPaint__content">
    //                                            {Array.isArray(listModuloRxPrt) && listModuloRxPrt.length > 0
    //                                                ? listModuloRxPrt.map((item, index) => (
    //                                                    <div
    //                                                        key={index}
    //                                                        className={`raioxPaint ${item.monRaiox.rxLogado ? "ativo" : ""}`}
    //                                                    >
    //                                                        <span className="tooltip">
    //                                                            <span className="tooltiptext">{`${item.monRaiox.descricao}`}</span>
    //                                                        </span>
    //                                                    </div>
    //                                                ))
    //                                                : null}
    //                                        </div>
    //                                    </Card>
    //                                </div>
    //                            </div>
    //                        </div>
    //                    )
    //                )
    //            ) : (
    //                <p>Nenhum setor encontrado</p>
    //            )
    //        ) : (
    //            <strong>Aguardando resposta do servidor...</strong>
    //        )}
    //    </div>
    //</BaseLayout>
    <BaseLayout>
        <div className="list-pts-monitoria">
            {state.listSetores ? (
                state.listSetores.length > 0 ? (
                    state.listSetores.map(
                        ({
                            setor,
                            id,
                            nome,
                            descricao,
                            status,
                            trafegoPortico,
                            listModuloRxPrt,
                            qtdRaioxGrade,
                            qtdRaioxAtual
                        }) => (
                            <div
                                className={`card-monitoria ${qtdRaioxAtual === qtdRaioxGrade ? "positive" : (qtdRaioxAtual > qtdRaioxGrade ? "accent" : "negative")}`}
                            >
                                <div className="card-monitoria__content">
                                    <header>
                                        <div className="topheader">
                                            <div className="mb-10">
                                                <h1 className="heading paragraph--lg color-secondary">
                                                    <strong>{nome}</strong>
                                                </h1>
                                                <p className="paragraph paragraph--sm color-secondary">
                                                    {descricao}
                                                </p>
                                            </div>
                                        </div>
                                    </header>
                                    <div className="contentCardRaiox">
                                        <Card>
                                            <p className="paragraph paragraph--sm">
                                                <strong>1. Programado: </strong>{qtdRaioxGrade} <br></br>
                                                <strong>2. Em operacao: </strong>{qtdRaioxAtual}
                                            </p>
                                        </Card>
                                    </div>
                                    {/*<div className="col-12 contentCardRaiox">*/}
                                    {/*    <Card>*/}
                                    {/*        <Chart*/}
                                    {/*            options={optionsChart}*/}
                                    {/*            type="bar"*/}
                                    {/*            data={state.graficoCards}*/}
                                    {/*        />*/}
                                    {/*    </Card>*/}
                                    {/*</div>*/}
                                    <div className="contentCardRaiox">
                                        <Card>
                                            {/*<div className="raioxPaint__content">*/}
                                            {/*    {Array.isArray(listModuloRxPrt) && listModuloRxPrt.length > 0*/}
                                            {/*        ? listModuloRxPrt.map((item, index) => (*/}
                                            {/*            <div*/}
                                            {/*                key={index}*/}
                                            {/*                className={`raioxPaint ${item.monRaiox.rxLogado ? "ativo" : ""}`}*/}
                                            {/*            >*/}
                                            {/*                <span className="tooltip">*/}
                                            {/*                    <span className="tooltiptext">{`${item.monRaiox.descricao}`}</span>*/}
                                            {/*                </span>*/}
                                            {/*            </div>*/}
                                            {/*        ))*/}
                                            {/*        : null}*/}
                                            {/*</div>*/}
                                            <div className="raioxPaint__content" style={{ marginBottom: '0.5rem' }}>
                                                <p className="paragraph paragraph--sm">
                                                    <strong>1.</strong>
                                                </p>
                                                {[...Array(18)].map((_, index) => (
                                                    <div
                                                        key={`grade-${index}`}
                                                        className={`raioxPaint ${index < qtdRaioxGrade ? "ativo" : ""}`}
                                                    >
                                                        <span className="tooltip">
                                                            <span className="tooltiptext">{index + 1}</span>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                            {/*<div className="raioxPaint__content">*/}
                                            {/*    {[...Array(18)].map((_, index) => (*/}
                                            {/*        <div*/}
                                            {/*            key={`atual-${index}`}*/}
                                            {/*            className={`raioxPaintAtual ${index < qtdRaioxAtual ? "positive" : (qtdRaioxAtual > index ? "accent" : (qtdRaioxAtual < index ? " negative" : "")) }`}*/}
                                            {/*        >*/}
                                            {/*            */}{/*${index < qtdRaioxAtual ? "ativo" : ""}*/}
                                            {/*            <span className="tooltip">*/}
                                            {/*                <span className="tooltiptext">{index + 1}</span>*/}
                                            {/*            </span>*/}
                                            {/*        </div>*/}
                                            {/*    ))}*/}
                                            {/*</div>*/}
                                            <div className="raioxPaint__content">
                                                <p className="paragraph paragraph--sm">
                                                    <strong>2.</strong>
                                                </p>
                                                {[...Array(18)].map((_, index) => {
                                                    let className = "raioxPaintAtual";

                                                    if (qtdRaioxAtual === 0) {
                                                        // Mant�m a classe base, sem adicionar nada
                                                    } else {
                                                        if (index < qtdRaioxAtual && qtdRaioxAtual === qtdRaioxGrade) {
                                                            className += " positive";
                                                        } else {
                                                            if (index < qtdRaioxAtual && qtdRaioxAtual > qtdRaioxGrade) {
                                                                className += " accent";
                                                            } else {
                                                                if (index < qtdRaioxAtual && qtdRaioxAtual < qtdRaioxGrade) {
                                                                    className += " negative";
                                                                }
                                                            }
                                                        }
                                                    }
                                                    return (
                                                        <div key={index} className={className}>
                                                            <span className="tooltip">
                                                                <span className="tooltiptext">{index + 1}</span>
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                ) : (
                    <p>Nenhum setor encontrado</p>
                )
            ) : (
                <strong>Aguardando resposta do servidor...</strong>
            )}
        </div>
    </BaseLayout>
    );
};
export default PagePerspecGlobal;